import React, { useEffect, useState } from "react";
import { ButtonYellow } from "../Components";
import { TypeAnimation } from "react-type-animation";
import { MotionAnimate } from "react-motion-animate";
import { ArrowDownCircleFill, ArrowRightCircle } from "react-bootstrap-icons";
import { Whitebg } from "../Assets";
import Web3 from "web3";
import { useDispatch } from "react-redux";
import { userLogin, userLoginWithInvite } from "../actions/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Home = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [wallet, setWallet] = useState()
  const query = useQuery();
  const invite = query.get("invite");
  const user = JSON.parse(localStorage.getItem('user'))
  const loading = useSelector(state => state?.auth?.isLoading)

 


  const handleLogin = (e) => {
    e.preventDefault();
  
    localStorage.clear()
    if (invite) {
      dispatch(userLoginWithInvite(wallet, navigate, invite));
    } else {
      dispatch(userLogin(wallet, navigate));
    }
    console.log("signup", invite);
   
  };

  // useEffect(() => {

  //   if(user){
  //     setWallet(user?.walletAddress)
  //   }

  // }, [user])

  const handleWallet = (e) => {
    setWallet(e.target.value);
  
  };

  const handleLoginRoute = () => {
    navigate("/airdrop")
  }

  console.log("invite", wallet)
  return (
    <div>
      {/* Landing */}
      <section>
        <div className="relative bg-neutral-900">
          <div className="absolute inset-x-0 bottom-0">
            <svg
              viewBox="0 0 224 12"
              fill="currentColor"
              className="w-full -mb-1 text-gray-100"
              preserveAspectRatio="none"
            >
              <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
            </svg>
          </div>

          <MotionAnimate reset={true} speed={3}>
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
              <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
                <h2 className="mb-6 font-sans text-5xl font-bold sm:text-6xl bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-400 bg-opacity-50">
                  Highlighting projects <br className="hidden md:block" />
                  developed on{" "}
                  <span className="relative inline-block px-2">
                    <div className="absolute inset-0 border border-amber-400" />
                    <span className="relative text-amber-400">
                      <TypeAnimation
                        sequence={["Blast L2", 1000]}
                        speed={500}
                        repeat={Infinity}
                      />
                    </span>
                  </span>
                </h2>
                <p className="mb-6 text-base text-white md:text-lg">
                  Explore BlastAlpha for valuable insights, updates, guides and
                  news within Blast.
                </p>
                <form className="flex flex-col items-center w-full mb-4 md:flex-row md:px-16">
                  <input
                    placeholder="Enter EVM Address"
                    required
                    onChange={handleWallet}
                    value={wallet}
                    type="text"
                    className="flex-grow w-max h-12 px-4 mb-3 text-neutral-900 transition duration-500 border-2 border-transparent rounded-2xl appearance-none md:mr-2 md:mb-0 focus:border-amber-500 focus:outline-none focus:shadow-outline"
                  />
                 
                   {!user ? <ButtonYellow type="submit" onclick={handleLogin } class="w-full text-white bg-blue-700 disabled:bg-slate-200 disabled:opacity-5 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 "  buttontext={`${user ? "Enter Dashboard": "Join Early Access"}`} disabled={!Web3.utils.isAddress(wallet)}>
                     <>{user ? <>Enter Dashboard</> : <> Join Early Access</>}</>
                     {loading && <span class="loader"></span>}
                  </ButtonYellow> : <ButtonYellow type="submit" onclick={wallet && wallet !== undefined && Web3.utils.isAddress(wallet)===true ? handleLogin: handleLoginRoute } class="w-full text-white bg-blue-700 disabled:bg-slate-200 disabled:opacity-5 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 "  buttontext={`${user ? "Enter Dashboard": "Join Early Access"}`} disabled={!user?.walletAddress}>
                      <>Enter Dashboard</> 
                     {loading && <span class="loader"></span>}
                  </ButtonYellow>}
                </form>
                <p className="max-w-md mb-10 text-xs tracking-wide text-indigo-100 sm:text-sm sm:mx-auto md:mb-16">
                  Join our early access to earn our blast developer airdrop + $Alpha Airdrop
                </p>
                <a
                  href="../#how-to"
                  aria-label="Scroll down"
                  className="flex items-center justify-center w-10 h-10 mx-auto text-white duration-300 transform border border-gray-400 rounded-full hover:text-teal-accent-400 hover:border-teal-accent-400 hover:shadow hover:scale-110"
                >
                  
                  <ArrowDownCircleFill class="text-amber-400" size={24} />
                </a>
              </div>
            </div>
          </MotionAnimate>
        </div>
      </section>

      {/* How to */}
      <section id="how-to" className="bg-no-repeat bg-cover bg-center"
      style={{
        backgroundImage: `url(${Whitebg})`,
      }}>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
            <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold text-left text-neutral-900 sm:text-4xl sm:leading-none md:mb-6 group">
              <span className="inline-block mb-1 sm:mb-4">
                How to get our {" "}
                <br className="hidden md:block" />
                Developer Aidrop + $Alpha Airdrop
              </span>
              <div className="h-1 ml-auto duration-300 origin-left transform bg-amber-400 scale-x-30 group-hover:scale-x-100" />
            </h2>
          </div>

          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="grid max-w-screen-lg gap-8 row-gap-6 sm:mx-auto lg:grid-cols-2">
              <a
                href="../airdrop/#social"
                aria-label="View item"
                title="View item"
                className="relative block p-px overflow-hidden transition duration-300 transform border rounded shadow-sm hover:scale-105 group hover:shadow-xl"
              >
                <div className="relative flex items-center justify-between p-5 bg-white rounded-sm">
                  <div className="pr-4">
                    <h6 className="mb-2 font-semibold">
                      Social Media Task
                    </h6>
                    <p className="text-sm text-neutral-900">
                      Follow, Like and Retweet on our social media platforms and
                      earn $alpha on each task
                    </p>
                  </div>
                  <div className="flex items-center justify-center">
                    <ArrowRightCircle class="text-amber-400" size={24} />
                  </div>
                </div>
              </a>

              <a
                href="../airdrop/#invite"
                aria-label="View item"
                title="View item"
                className="relative block p-px overflow-hidden transition duration-300 transform border rounded shadow-sm hover:scale-105 group hover:shadow-xl"
              >
                <div className="relative flex items-center justify-between p-5 bg-white rounded-sm">
                  <div className="pr-4">
                    <h6 className="mb-2 font-semibold">
                      Invite Task
                    </h6>
                    <p className="text-sm text-gray-900">
                      Invite your friends using your unique invite link and earn as much as you invite
                    </p>
                  </div>
                  <div className="flex items-center justify-center">
                  <ArrowRightCircle class="text-amber-400" size={24} />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
