import { SIGN_UP, SIGN_IN, START_LOADING, END_LOADING, NOTIFY, UPDATE_USER_STATE, LOG_OUT, UPDATE_USERS, UPDATE_FEST_USERS,UPDATE_STATS } from "../constants"
import * as api from "../api"

import { toast } from "react-toastify"



export const userLogin = (wallet, navigate) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING })
        console.log("userdata", wallet, "noinvite")
      
console.log("userloginwithnoinvite", wallet)
        const { data } = await api.userLogin(wallet )
        console.log("userdatanoinvite",data, wallet)

     
        localStorage.setItem("user",JSON.stringify(data.user))
        data.success && navigate(`/airdrop`)

        dispatch({ type: END_LOADING })
            
   


    } catch (error) {

    }

}
export const getUser = (wallet, navigate) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING })
      

        const { data } = await api.getUser(wallet )
        console.log("userdatanoinvite",data, wallet)

     
        localStorage.setItem("user",JSON.stringify(data.user))
        data.success && navigate(`/airdrop`)

        dispatch({ type: END_LOADING })
            
   


    } catch (error) {

    }

}
export const userLoginWithInvite = (wallet, navigate, invite) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING })
        console.log("userdata", wallet, invite)
          
        console.log("userdatawithinvite", wallet, invite)

                const { data } = await api.userLoginWithInvite(wallet, invite )
                console.log("userdata",data, wallet)

                localStorage.setItem("user",JSON.stringify(data.user))
              
                data.success && navigate(`/airdrop`)
        
                dispatch({ type: END_LOADING })
            
   


    } catch (error) {

    }

}



export const usersignin = () => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.userSignIn()
        console.log(data)


        data.success && window.open(`${data.path}`)

        dispatch({ type: END_LOADING })


    } catch (error) {

    }

}
export const userlogout = ( navigate) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING })

        dispatch({ type: LOG_OUT })
        navigate("/")
        dispatch({ type: END_LOADING })




    } catch (error) {

    }

}
export const getAllUsers = () => async (dispatch) => {
    try {
        const { data } = await api.getAllUsers()
        console.log("data", data)
        data.success && dispatch({ type: UPDATE_USERS, data: data })
     




    } catch (error) {

    }

}





// export const getUser = (userId) => async (dispatch) => {

//     try {
//         dispatch({ type: START_LOADING })
//         console.log("userId", userId)
//         const { data } = await api.getUser(userId)
//         console.log("datauser", data)

//         data.success && dispatch({ type: UPDATE_USER_STATE, data: data })
//         // window.location.reload()
//         dispatch({ type: END_LOADING })

//         console.log(userId)

//     } catch (error) {

//     }

// }


export const followReward = (wallet, twitterName) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.followReward(wallet,twitterName)
        console.log(data)

        dispatch({ type: END_LOADING })

        data.error && dispatch(toast.error(<>{data.message}</>))
        data.success && dispatch({ type: UPDATE_USER_STATE, data: data })

        data.success && dispatch(toast.success(<>{data.message}</>))


    } catch (error) {

    }

}
export const followAlphaReward = (wallet, twitterName) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.followAlphaReward(wallet, twitterName)
        console.log(data)

        dispatch({ type: END_LOADING })

        data.error && dispatch(toast.error(<>{data.message}</>))
        data.success && dispatch({ type: UPDATE_USER_STATE, data: data })
        data.success && dispatch(toast.success(<>{data.message}</>))



    } catch (error) {

    }

}
export const followBetaReward = (wallet, twitterName) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.followBetaReward(wallet, twitterName)
        console.log(data)

        dispatch({ type: END_LOADING })

        data.error && dispatch(toast.error(<>{data.message}</>))
        data.success && dispatch({ type: UPDATE_USER_STATE, data: data })
        data.success && dispatch(toast.success(<>{data.message}</>))



    } catch (error) {

    }

}
export const followcharlyReward = (wallet, twitterName) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.followcharlyReward(wallet, twitterName)
        console.log(data)

        dispatch({ type: END_LOADING })

        data.error && dispatch(toast.error(<>{data.message}</>))
        data.success && dispatch({ type: UPDATE_USER_STATE, data: data })
        data.success && dispatch(toast.success(<>{data.message}</>))



    } catch (error) {

    }

}
export const followDeltaReward = (wallet, twitterName) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.followDeltaReward(wallet, twitterName)
        console.log(data)

        dispatch({ type: END_LOADING })

        data.error && dispatch(toast.error(<>{data.message}</>))
        data.success && dispatch({ type: UPDATE_USER_STATE, data: data })
        data.success && dispatch(toast.success(<>{data.message}</>))



    } catch (error) {

    }

}
export const followEchoReward = (wallet, twitterName) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.followEchoReward(wallet, twitterName)
        console.log(data)

        dispatch({ type: END_LOADING })

        data.error && dispatch(toast.error(<>{data.message}</>))
        data.success && dispatch({ type: UPDATE_USER_STATE, data: data })
        data.success && dispatch(toast.success(<>{data.message}</>))



    } catch (error) {

    }

}

export const likeReward = (wallet,twitterName) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.likeReward(wallet, twitterName)
        console.log(data)

        dispatch({ type: END_LOADING })

        data.error && dispatch(toast.error(<>{data.message}</>))
        data.success && dispatch({ type: UPDATE_USER_STATE, data: data })
        data.success && dispatch(toast.success(<>{data.message}</>))



    } catch (error) {

    }

}

export const likeAlphaReward = (wallet,twitterName) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.likeAlphaReward(wallet, twitterName)
        console.log(data)

        dispatch({ type: END_LOADING })

        data.error && dispatch(toast.error(<>{data.message}</>))
        data.success && dispatch({ type: UPDATE_USER_STATE, data: data })
        data.success && dispatch(toast.success(<>{data.message}</>))



    } catch (error) {

    }

}
export const likeBetaReward = (wallet,twitterName) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.likeBetaReward(wallet, twitterName)
        console.log(data)

        dispatch({ type: END_LOADING })

        data.error && dispatch(toast.error(<>{data.message}</>))
        data.success && dispatch({ type: UPDATE_USER_STATE, data: data })
        data.success && dispatch(toast.success(<>{data.message}</>))



    } catch (error) {

    }

}
export const discordReward = (wallet, discordName) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING })
        console.log("discordapi",discordName,wallet )

        const { data } = await api.discordReward(wallet, discordName)
        console.log("discordapi",data)

        dispatch({ type: END_LOADING })

        data.error && dispatch(toast.error(<>{data.message}</>))
        data.success && dispatch({ type: UPDATE_USER_STATE, data: data })

        data.success && dispatch(toast.success(<>{data.message}</>))


    } catch (error) {

    }

}

export const tgReward = (wallet, tgName) => async (dispatch, tgName) => {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.tgReward(wallet, tgName)
        console.log(data)

        dispatch({ type: END_LOADING })

        data.error && dispatch(toast.error(<>{data.message}</>))
        data.success && dispatch({ type: UPDATE_USER_STATE, data: data })
        data.success && dispatch(toast.success(<>{data.message}</>))



    } catch (error) {

    }

}
export const tgAlphaReward = (wallet, tgName) => async (dispatch, tgName) => {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.tgAlphaReward(wallet, tgName)
        console.log(data)

        dispatch({ type: END_LOADING })

        data.error && dispatch(toast.error(<>{data.message}</>))
        data.success && dispatch({ type: UPDATE_USER_STATE, data: data })
        data.success && dispatch(toast.success(<>{data.message}</>))



    } catch (error) {

    }

}
export const tgBetaReward = (wallet, tgName) => async (dispatch, tgName) => {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.tgBetaReward(wallet, tgName)
        console.log(data)

        dispatch({ type: END_LOADING })

        data.error && dispatch(toast.error(<>{data.message}</>))
        data.success && dispatch({ type: UPDATE_USER_STATE, data: data })
        data.success && dispatch(toast.success(<>{data.message}</>))



    } catch (error) {

    }

}
export const tweetReward = (wallet, twitterName) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.tweetReward(wallet, twitterName)
        console.log(data)

        dispatch({ type: END_LOADING })

        data.error && dispatch(toast.error(<>{data.message}</>))
        data.success && dispatch({ type: UPDATE_USER_STATE, data: data })

        data.success && dispatch(toast.success(<>{data.message}</>))


    } catch (error) {

    }

}

/**************** Fest Reward */


