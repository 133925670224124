import axios from "axios";


const API = axios.create({ baseURL: "https://alphaserver-bq3z.onrender.com" });
// const API = axios.create({ baseURL: "http://localhost:5000" });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`;
  }
  return req;
});



export const userLogin = (wallet) => API.post(`/login`, {wallet});
export const getUser = (wallet) => API.get(`/login/user`, {wallet});
export const userLoginWithInvite = (wallet, invite) => API.post(`/login/invite`, {wallet, invite});
export const userSignup = (wallet) => API.get(`/signup`,{wallet});
export const userSignIn = () => API.get(`/auth`);
export const getAllUsers = () => API.get(`/user/all`);
export const addWallet = (userId, wallet) => API.patch(`/wallet/${userId}`, {wallet});
// export const getUser = (userId) => API.get(`/user/${userId}`, {userId});


export const followReward = (wallet, twitterName) => API.patch(`/task/follow`, {wallet,twitterName});
export const followAlphaReward = (wallet,twitterName) => API.patch(`/task/followAlpha`, {wallet, twitterName});
export const followBetaReward = (wallet,twitterName) => API.patch(`/task/followBeta`, {wallet, twitterName});
export const followcharlyReward = (wallet,twitterName) => API.patch(`/task/followCharly`, {wallet, twitterName});
export const followDeltaReward = (wallet,twitterName) => API.patch(`/task/followDelta`, {wallet, twitterName});
export const followEchoReward = (wallet,twitterName) => API.patch(`/task/followEcho`, {wallet, twitterName});
export const likeReward = (wallet, twitterName) => API.patch(`/task/like`, {wallet, twitterName});
export const likeAlphaReward = (wallet, twitterName) => API.patch(`/task/likeAlpha`, {wallet, twitterName});
export const likeBetaReward = (wallet, twitterName) => API.patch(`/task/likeBeta`, {wallet, twitterName});
export const discordReward = (wallet, discordName) => API.patch(`/task/discord`, {wallet, discordName});
export const tgReward = (wallet, tgName) => API.patch(`/task/tg`, {wallet, tgName});
export const tgAlphaReward = (wallet, tgName) => API.patch(`/task/tgAlpha`, {wallet, tgName});
export const tgBetaReward = (wallet, tgName) => API.patch(`/task/tgBeta`, {wallet, tgName});
export const tweetReward = (wallet,twitterName) => API.patch(`/task/tweet`, {wallet, twitterName});


/*********Fest Task */

