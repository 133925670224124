import React from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import "./App.css";
import { BackTop } from "antd";
import { Footer, Navbar } from "./Components";
import { Airdrop, Home, NoPage } from "./Pages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function App() {
  return (
    <div className="App bg-neutral-900">
      <ToastContainer/>

      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/airdrop" element={<Airdrop/>} />
          <Route path="/*" element={<NoPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>

      <BackTop />
    </div>
  );
}

export default App;
