import React, { useState } from "react";
import { Logo } from "../Assets";
import ButtonYellow from "./ButtonYellow";
import { List, X } from "react-bootstrap-icons";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div>
      <div class="bg-neutral-900">
        <div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div class="relative flex items-center justify-between">
            <a href="/" class="inline-flex items-center">
              <img src={Logo} alt="Logo" className="w-20" />
            </a>
            <ul class="flex items-center hidden space-x-8 lg:flex">
              <li>
                <a
                  href="../"
                  class="font-medium text-white duration-500 hover:text-amber-400"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="../airdrop"
                  class="font-medium text-white duration-500 hover:text-amber-400"
                >
                  Airdrop
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/BlastAlpha_"
                  class="font-medium text-white duration-500 hover:text-amber-400"
                >
                  Community
                </a>
              </li>
            </ul>
            <ul class="flex items-center hidden space-x-8 lg:flex">
              <li>
                <ButtonYellow
                  buttonlink="../airdrop"
                  buttontext="Join Early Access"
                />
              </li>
            </ul>
            <div class="lg:hidden">
              <button
                class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
                onClick={() => setIsMenuOpen(true)}
              >
                <List class="text-amber-400" size={24}/>
              </button>
              {isMenuOpen && (
                <div class="z-10 absolute top-0 left-0 w-full">
                  <div class="p-5 bg-neutral-900 border border-amber-400 rounded shadow-sm">
                    <div class="flex items-center justify-between mb-4">
                      <div>
                        <a href="/" class="iitems-center">
                          <img src={Logo} alt="Logo" className="w-20" />
                        </a>
                      </div>
                      <div>
                        <button
                          class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200  focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          <X class="text-amber-400" size={32}/>
                        </button>
                      </div>
                    </div>
                    <nav>
                      <ul class="space-y-4">
                        <li>
                          <a
                            href="../"
                            class="font-medium text-white duration-500 hover:text-amber-400"
                          >
                            Home
                          </a>
                        </li>
                        <li>
                          <a
                            href="../airdrop"
                            class="font-medium text-white duration-500 hover:text-amber-400"
                          >
                            Airdrop
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/BlastAlpha_"
                            class="font-medium text-white duration-500 hover:text-amber-400"
                          >
                            Community
                          </a>
                        </li>

                        <li>
                          <ButtonYellow
                            buttonlink="../airdrop"
                            buttontext="Join Early Access"
                          />
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
