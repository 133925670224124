import React from "react";
import { Telegram, TwitterX } from "react-bootstrap-icons";

const Footer = () => {
  const current = new Date();
  const date = current.getFullYear();
  return (
    <div>
      <div className="bg-neutral-900">
        <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div className="flex flex-col justify-between pt-5 pb-10 border-t border-amber-400 sm:flex-row">
            <p className="text-sm text-white">
              &copy; {date} Blast Alpha All rights reserved.
            </p>
            <div className="flex items-center justify-center mt-4 space-x-4 sm:mt-0">
              <a
                href="https://twitter.com/BlastAlpha_"
                className="text-amber-400 transition-colors duration-300 hover:text-amber-500"
              >
                <TwitterX/>
              </a>
              <a
                href="https://t.me/blast_alpha"
                className="text-amber-400 transition-colors duration-300 hover:text-amber-500"
              >
                <Telegram/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
