import React from "react";

const ButtonYellow = ({ buttonlink, buttontext, onclick,disabled }) => {
  return (
    <div>
      <button className="disabled:bg-slate-200 disabled:opacity-30" onClick={onclick} disabled={disabled} >
        <a
          href={buttonlink}
          class="inline-flex items-center justify-center w-full h-12 px-6 md:w-auto font-medium text-neutral-900 bg-amber-400 hover:bg-amber-500 transition duration-500 rounded-2xl shadow-md shadow-amber-400/10 disabled:bg-slate-200 disabled:opacity-5"
          
        >
          {buttontext}
        </a>
      </button>
    </div>
  );
};

export default ButtonYellow;
