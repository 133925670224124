import { USER_UPDATE, UPDATE_USER_STATE , UPDATE_REWARD_STATE, UPDATE_USERS, UPDATE_FEST_USERS, UPDATE_STATS} from "../constants";

let userData = {};

const userReducers = (state = {   user:[], rewards:[], users:[], stats:[]}, action) => {
  switch (action.type) {
    
    case UPDATE_USER_STATE:
      console.log("UPDATE_USER_STATE", action.data.user)
      localStorage.setItem('user',JSON.stringify(action.data.user) )
      // window.location.reload(false)

      console.log("stateUser1", action.data.user)

      return { ...state, user:action.data.user };
    case UPDATE_USERS:
  

      return { ...state, users:action.data.users };
    case UPDATE_FEST_USERS:
  

      return { ...state, festUsers:action.data.users };
    case UPDATE_STATS:
  

      return { ...state, stats:action.data.stats };
    case UPDATE_REWARD_STATE:
  
      return { ...state, rewards: action.payload.data };

    default:
      return state;
  }
};

export default userReducers;
