import React, { useEffect, useState } from "react";
import { ButtonYellow } from "../Components";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux'
import { discordReward, followAlphaReward, followBetaReward, followDeltaReward, followEchoReward, followReward, followcharlyReward, getAllUsers, getUser, likeAlphaReward, likeBetaReward, likeReward, tgAlphaReward, tgBetaReward, tgReward, tweetReward, userLogin } from "../actions/auth";
import { useNavigate } from "react-router-dom";

const Airdrop = () => {
  // const copyToClipboard = (e) => {
  //   e.preventDefault();
  //   const copyText = document.getElementById("copyText");
  //   copyText.select();
  //   document.execCommand("copy");
  //   toast.success("Invite link copied to clipboard!");
  // };

  const [loading, setLoading] = useState(false)
  const [index, setIndex] = useState(false)
  const [follow, setFollow] = useState()
  const [followAlpha, setFollowAlpha] = useState()
  const [followBeta, setFollowBeta] = useState()
  const [followCharly, setFollowCharly] = useState()
  const [followDelta, setFollowDelta] = useState()
  const [followEcho, setFollowEcho] = useState()

  const [like, setLike] = useState()
  const [likeAlpha, setLikeAlpha] = useState()
  const [likeBeta, setLikeBeta] = useState()
  const [tg, setTg] = useState()
  const [tgAlpha, setTgAlpha] = useState()
  const [tgBeta, setTgBeta] = useState()
  const [discord, setDiscord] = useState()
  const [tweet, setTweet] = useState()
  const [twitterUserName, setTwitterUserName] = useState()
  const [twitterDefaultUserName, setTwitterDefaultUserName] = useState()
  const [tgUserName, setTgUserName] = useState()
  const [tgDefaultUserName, setTgDefaultUserName] = useState()
  const [discordUserName, setDiscordUserName] = useState()
  const [twitterTweet, setTwitterTweet] = useState()
  const [myRank, setMyRank] = useState()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const users = useSelector(state => state?.users?.users)
  const isLoading = useSelector(state => state.auth.isLoading)

  const user = JSON.parse(localStorage.getItem("user"))
  // const user = JSON.parse(localStorage.getItem("userMain"))
  // const userLatest = JSON.parse(localStorage.getItem("user"))
  const copyToClipboard = (e) => {
    e.preventDefault()
    const copyText = document.getElementById('copyText');
    copyText.select();
    document.execCommand('copy');
    toast.success("Invite link copied to clipboard!");
  }

  /*-------------------------------Start Here state section Template---------------------*/


  //tg template state

  // follow template state



  /*-------------------------------Handler Function section Template---------------------*/

  //Tg handler function Template



  // Follow handler Function Template


  // Discord handler Function Template


  /*-------------------------------Reward section Template---------------------*/

  // Tg Reward function Template


  // Follow Reward function Template



  // Discord Reward Template




  /*-------------------------------------------Ends Here---------------------------*/





  const handleFollow = (index) => {
    // e.preventDefault()
    console.log("followclick")
    window.open(
      `https://twitter.com/BlastAlpha_`,
      "_blank",
      "noopener"
    )
    setIndex(index)
    setLoading(true)
    console.log()
    setTimeout(() => {
      setFollow(true)
      setLoading(false)

    }, 10000);
  }

  const handleFollowAlpha = (index) => {
    
    console.log("followclick")
    window.open(
      `https://twitter.com/Blastopedia/`,
      "_blank",
      "noopener"
    )
    setIndex(index)
    setLoading(true)
    console.log()
    setTimeout(() => {
      setFollowAlpha(true)
      setLoading(false)

    }, 10000);
  }
  const handleFollowBeta = (index) => {
    // e.preventDefault()
    console.log("followclick")
    window.open(
      `https://twitter.com/Blastarians_`,
      "_blank",
      "noopener"
    )
    setIndex(index)
    setLoading(true)
    console.log()
    setTimeout(() => {
      setFollowBeta(true)
      setLoading(false)

    }, 10000);
  }
  const handleFollowCharly = (index) => {
    // e.preventDefault()
    console.log("followclick")
    window.open(
      `https://twitter.com/genche_x`,
      "_blank",
      "noopener"
    )
    setIndex(index)
    setLoading(true)
    console.log()
    setTimeout(() => {
      setFollowCharly(true)
      setLoading(false)

    }, 10000);
  }
  const handleFollowDelta = (index) => {
    // e.preventDefault()
    console.log("followclick")
    window.open(
      `https://twitter.com/BlastEcosystem_`,
      "_blank",
      "noopener"
    )
    setIndex(index)
    setLoading(true)
    console.log()
    setTimeout(() => {
      setFollowDelta(true)
      setLoading(false)

    }, 10000);
  }
  const handleFollowEcho = (index) => {
    // e.preventDefault()
    console.log("followclick")
    window.open(
      `https://twitter.com/aipacxyz/`,
      "_blank",
      "noopener"
    )
    setIndex(index)
    setLoading(true)
    console.log()
    setTimeout(() => {
      setFollowEcho(true)
      setLoading(false)

    }, 10000);
  }



  const handleLike = (index) => {
    // e.preventDefault()
    window.open(
      `https://x.com/BlastAlpha_/status/1795130676833857899?s=19`,
      "_blank",
      "noopener"
    )
    setIndex(index)
    setLoading(true)
    console.log()
    setTimeout(() => {
      setLike(true)
      setLoading(false)

    }, 10000);
  }
  // const handleLikeAlpha = (index) => {
  //   // e.preventDefault()
  //   window.open(
  //     `https://twitter.com/aipacxyz/status/1762751339971416564?t=N6loy1rRDLTLfCFxU_MuIw&s=19`,
  //     "_blank",
  //     "noopener"
  //   )
  //   setIndex(index)
  //   setLoading(true)
  //   console.log()
  //   setTimeout(() => {
  //     setLikeAlpha(true)
  //     setLoading(false)

  //   }, 10000);
  // }
  
  // const handleLikeBeta = (index) => {
  //   // e.preventDefault()
  //   window.open(
  //     `https://twitter.com/Blastarians_/status/1762906802524463518?t=RpozQxF_KnHAKCkwnmi38w&s=19`,
  //     "_blank",
  //     "noopener"
  //   )
  //   setIndex(index)
  //   setLoading(true)
  //   console.log()
  //   setTimeout(() => {
  //     setLikeBeta(true)
  //     setLoading(false)

  //   }, 10000);
  // }
  const handleTg = (index) => {
    // e.preventDefault()
    window.open(
      `https://t.me/alphapham`,
      "_blank",
      "noopener"
    )
    setIndex(index)
    setLoading(true)
    console.log()
    setTimeout(() => {
      setTg(true)
      setLoading(false)

    }, 10000);
  }
  const handleTgAlpha = (index) => {
    // e.preventDefault()
    window.open(
      `https://t.me/blast_alpha`,
      "_blank",
      "noopener"
    )
    setIndex(index)
    setLoading(true)
    console.log()
    setTimeout(() => {
      setTgAlpha(true)
      setLoading(false)

    }, 10000);
  }
  const handleTgBeta = (index) => {
    // e.preventDefault()
    window.open(
      `https://t.me/blastopediaofficial`,

      "_blank",
      "noopener"
    )
    setIndex(index)
    setLoading(true)
    console.log()
    setTimeout(() => {
      setTgBeta(true)
      setLoading(false)

    }, 10000);
  }
  const handleDiscord = (index) => {
    // e.preventDefault()
    window.open(
      ``,
      "_blank",
      "noopener"
    )
    setIndex(index)
    setLoading(true)
    console.log()
    setTimeout(() => {
      setDiscord(true)
      setLoading(false)

    }, 10000);
  }
  const handleTweet = (index) => {

   
const text = `Just joined the @BlastAlpha_ Blast Developer airdrop!%0a%0aWith the @Blast_L2 mainnet going live in under 7 days, now's the ideal time to get involved.%0a%0aDon't hesitate, register now! 👇%0a%0ahttps://blastalpha.xyz?invite=${user?.referralCode}%0a%0a%23BlastAlpha %23Blast_L2`

    window.open(
      `https://twitter.com/intent/tweet?&text=${text}`,
      "_blank",
      "noopener"
    )
    setIndex(index)
    setLoading(true)
    console.log()
    setTimeout(() => {
      setTweet(true)
      setLoading(false)
    }, 10000);
  }

  const handleFollowReward = () => {
    dispatch(followReward(user?.walletAddress, twitterUserName))
  }
  const handleFollowAlphaReward = () => {
    dispatch(followAlphaReward(user?.walletAddress, twitterUserName))
  }
  const handleFollowBetaReward = () => {
    dispatch(followBetaReward(user?.walletAddress, twitterUserName))
  }
  const handleFollowCharlyReward = () => {
    dispatch(followcharlyReward(user?.walletAddress, twitterUserName))
  }
  const handleFollowDeltaReward = () => {
    dispatch(followDeltaReward(user?.walletAddress, twitterUserName))
  }
  const handleFollowEchoReward = () => {
    dispatch(followEchoReward(user?.walletAddress, twitterUserName))
  }



  const handleLikeReward = () => {
    dispatch(likeReward(user?.walletAddress, twitterUserName))

  }
  const handleLikeAlphaReward = () => {
    dispatch(likeAlphaReward(user?.walletAddress, twitterUserName))

  }
  const handleLikeBetaReward = () => {
    dispatch(likeBetaReward(user?.walletAddress, twitterUserName))

  }
  const handleDiscordReward = () => {
    dispatch(discordReward(user?.walletAddress, discordUserName))

  }
  const handleTgReward = () => {
    dispatch(tgReward(user?.walletAddress, tgUserName))

  }
  const handleTgAlphaReward = () => {
    dispatch(tgAlphaReward(user?.walletAddress, tgUserName))

  }
  const handleTgBetaReward = () => {
    dispatch(tgBetaReward(user?.walletAddress, tgUserName))

  }
  const handleTweetReward = () => {
    dispatch(tweetReward(user?.walletAddress, twitterUserName))

  }


  const handleTwitterUserName = (e) => {
    setTwitterDefaultUserName(" ")

    setTwitterUserName(e.target.value)

  }
  const handleTgUserName = (e) => {
    setTgDefaultUserName(" ")
    setTgUserName(e.target.value)

  }
  const handleDiscordUserName = (e) => {

    setDiscordUserName(e.target.value)

  }
  const handleTwitterTweet = (e) => {

    setTwitterTweet(e.target.value)

  }
  useEffect(() => {

    if (!user) {
      navigate("/")
    } 
    
    else {
      if(user?.walletAddress){
        dispatch(userLogin(user?.walletAddress))
  
      }
    }

  }, [user])
  // userLatest

  useEffect(() => {
    dispatch(getAllUsers())
 
  }, [])


  

  return (
    <div>
      <Toaster />
      {/* Page Header */}
      <section>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-lg sm:text-center sm:mx-auto">
            <h2 className="mb-6 font-sans text-3xl font-bold text-white sm:text-4xl bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-400 bg-opacity-50">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-32 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="6b0188f3-b7a1-4e9b-b95e-cad916bb3042"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#6b0188f3-b7a1-4e9b-b95e-cad916bb3042)"
                    width="52"
                    height="24"
                  />
                </svg>
              </span>
              Claim{" "}
              <span className="relative px-2 bg-neutral-900 border border-amber-400 text-amber-400">
                Airdrop
              </span>
            </h2>
            <hr className="my-8 border-amber-400" />
          </div>
        </div>
      </section>

      {/* Airdrop Status */}
      <section>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-6">
          <div className="bg-white p-8 rounded shadow-xl sm:p-16">
            <div className="flex flex-col lg:flex-row">
              <div className="mb-6 lg:mb-0 lg:w-1/2 lg:pr-5">
                <h2 className="font-sans text-3xl font-bold text-neutral-900 sm:text-4xl sm:leading-none">
                  Blast Developer
                  <br className="hidden md:block" />
                  Airdrop{" "} + $Alpha Airdrop
                </h2>
                <p className="mb-4 text-base text-gray-700">
                  Claiming opened till March 30th 2024
                </p>
              </div>
              <div className="lg:w-1/2">
                <div className="grid gap-5 row-gap-8 sm:grid-cols-2">
                  <div className="bg-white border-l-4 shadow-sm border-amber-400">
                    <div className="h-full p-5 border border-l-0 rounded-r">
                      <h6 className="mb-2 font-semibold leading-5">
                        Eligibility Status
                      </h6>
                      <p className="text-sm text-gray-900">Pending</p>
                    </div>
                  </div>
                  <div className="bg-white border-l-4 shadow-sm border-amber-400">
                    <div className="h-full p-5 border border-l-0 rounded-r">
                      <h6 className="mb-2 font-semibold leading-5">
                        Earned Allocation
                      </h6>
                      <p className="text-sm text-gray-900">{user?.totalPoint || 0} $Alphas</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Invite Task */}
      <section id="invite">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-2xl mx-auto sm:max-w-xl md:max-w-2xl">
            <div className="text-center">
              <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <div></div>
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-amber-400 sm:text-4xl md:mx-auto">
                  Invite Task
                </h2>
                <p className="text-base text-white md:text-lg">
                  Earn 3500 more Alpha for you and your friend when you refer
                </p>
              </div>
              <form className="flex flex-col items-center w-full mb-4 md:flex-row md:px-16">
                <input
                  type="text"

                  value={`https://www.blastalpha.xyz?invite=${user?.walletAddress}`}
                  id="copyText"
                  readOnly
                  placeholder="EVM Address"
                  required
                  className="flex-grow w-max h-12 px-4 mb-3 text-neutral-900 transition duration-500 border-2 border-transparent rounded-2xl appearance-none md:mr-2 md:mb-0 focus:border-amber-500 focus:outline-none focus:shadow-outline"
                />
                <ButtonYellow onclick={copyToClipboard} buttontext="Copy" />
              </form>
              <p className="max-w-md mx-auto mb-10 text-xs text-white sm:text-sm md:mb-16">
                Click the 'Copy' button to copy your invite link
              </p>
              <div className="p-2">
                <div className="grid gap-5 row-gap-8 sm:grid-cols-2">
                  <div className="bg-white border-l-4 shadow-sm border-amber-400">
                    <div className="h-full p-5 border border-l-0 rounded-r">
                      <h6 className="mb-2 font-semibold leading-5">
                        Invited Friends
                      </h6>
                      <p className="text-sm text-gray-900">{user?.referrals?.length || 0}</p>
                    </div>
                  </div>
                  <div className="bg-white border-l-4 shadow-sm border-amber-400">
                    <div className="h-full p-5 border border-l-0 rounded-r">
                      <h6 className="mb-2 font-semibold leading-5">
                        Earned Rewards
                      </h6>
                      <p className="text-sm text-gray-900">{user?.referrals?.length * 3500 || 0}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Social Media task */}
      <section id="social">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold text-amber-400 sm:text-4xl md:mx-auto">
              Social Media Task
            </h2>
            <p className="text-base text-white md:text-lg">
              Earn Alpha by doing our social media task
            </p>
          </div>
          <div className="grid gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3">
            <div className="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2 space-y-4">
              <h6 className="mb-2 font-semibold leading-5">Follow Blast Alpha</h6>
              <p className="text-sm text-gray-900">For 1000 $Alphas</p>
              {/* <ButtonYellow buttonlink={"https://x.com"} buttontext="Twitter" /> */}

              {!follow ?
                <>{!loading || index !== 1 ?
                  <ButtonYellow
                    type="submit"
                    onclick={() => handleFollow(1)}
                    className=" bg-red-400"
                    disabled={user?.followStatus}
                    // disabled={true}
                    buttontext='Follow'
                  >

                    Follow


                  </ButtonYellow>
                  :
                  <>{index === 1 && <button disabled type="button" class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                    <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>
                    Loading...
                  </button>}</>}
                </> : <div class="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">

                    {/* <div class='h-[2px]' style>
                                                        @

                                                    </div> */}
                  </div>


                  <input type="text" id="text" onChange={handleTwitterUserName} value={twitterUserName || twitterDefaultUserName} class=" p-4 mb-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Twitter Username" required disabled={user?.followStatus} />
                  <ButtonYellow
                    type="submit"
                    onclick={handleFollowReward}
                    disabled={twitterDefaultUserName ? false : user?.followStatus || !twitterDefaultUserName || !twitterUserName}
                    buttontext=' Collect Reward'
                  >
                    Collect Reward &nbsp; <>{isLoading && index === 1 && <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>}</>
                  </ButtonYellow>
                </div>
              }
            </div>
            <div className="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2 space-y-4">
              <h6 className="mb-2 font-semibold leading-5">Follow our Partner</h6>
              <p className="text-sm text-gray-900">For 1000 $Alphas</p>
           
              {!followEcho ?
                <>{!loading || index !== 12 ?
                  <ButtonYellow
                    type="submit"
                    onclick={() => handleFollowAlpha(12)}
                    className=" bg-red-400"
                    disabled={user?.followEchoStatus}
                
                    buttontext='Follow'
                  >

                    Follow Alpha


                  </ButtonYellow>
                  :
                  <>{index === 12 && <button disabled type="button" class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                    <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>
                    Loading...
                  </button>}</>}
                </> : <div class="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">

                  
                  </div>


                  <input type="text" id="text" onChange={handleTwitterUserName} value={twitterUserName || twitterDefaultUserName} class=" p-4 mb-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Twitter Username" required disabled={user?.followEchoStatus} />
                  <ButtonYellow
                    type="submit"
                    onclick={handleFollowEchoReward}
                    disabled={twitterDefaultUserName ? false : user?.followEchoStatus || !twitterDefaultUserName || !twitterUserName}
                    buttontext=' Collect Reward'
                  >
                    Collect Reward &nbsp; <>{isLoading && index === 12 && <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>}</>
                  </ButtonYellow>
                </div>
              }
            </div>
            {/* <div className="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2 space-y-4">
              <h6 className="mb-2 font-semibold leading-5">Follow our Partner</h6>
              <p className="text-sm text-gray-900">For 1000 $Alphas</p>
           
              {!followAlpha ?
                <>{!loading || index !== 2 ?
                  <ButtonYellow
                    type="submit"
                    onclick={() => handleFollowAlpha(2)}
                    className=" bg-red-400"
                    disabled={user?.followAlphaStatus}
                
                    buttontext='Follow'
                  >

                    Follow Alpha


                  </ButtonYellow>
                  :
                  <>{index === 2 && <button disabled type="button" class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                    <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>
                    Loading...
                  </button>}</>}
                </> : <div class="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">

                  
                  </div>


                  <input type="text" id="text" onChange={handleTwitterUserName} value={twitterUserName || twitterDefaultUserName} class=" p-4 mb-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Twitter Username" required disabled={user?.followAlphaStatus} />
                  <ButtonYellow
                    type="submit"
                    onclick={handleFollowAlphaReward}
                    disabled={twitterDefaultUserName ? false : user?.followAlphaStatus || !twitterDefaultUserName || !twitterUserName}
                    buttontext=' Collect Reward'
                  >
                    Collect Reward &nbsp; <>{isLoading && index === 2 && <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>}</>
                  </ButtonYellow>
                </div>
              }
            </div> */}
            <div className="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2 space-y-4">
              <h6 className="mb-2 font-semibold leading-5">Follow our Partner</h6>
              <p className="text-sm text-gray-900">For 1000 $Alphas</p>
           

              {!followBeta ?
                <>{!loading || index !== 3 ?
                  <ButtonYellow
                    type="submit"
                    onclick={() => handleFollowBeta(3)}
                    className=" bg-red-400"
                    disabled={user?.followBetaStatus}
                   
                    buttontext='Follow'
                  >

                    Follow Beta


                  </ButtonYellow>
                  :
                  <>{index === 3 && <button disabled type="button" class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                    <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>
                    Loading...
                  </button>}</>}
                </> : <div class="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">

                 
                  </div>


                  <input type="text" id="text" onChange={handleTwitterUserName} value={twitterUserName || twitterDefaultUserName} class=" p-4 mb-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Twitter Username" required disabled={user?.followBetaStatus} />
                  <ButtonYellow
                    type="submit"
                    onclick={handleFollowBetaReward}
                    disabled={twitterDefaultUserName ? false : user?.followBetaStatus || !twitterDefaultUserName || !twitterUserName}
                    buttontext=' Collect Reward'
                  >
                    Collect Reward &nbsp; <>{isLoading && index === 3 && <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>}</>
                  </ButtonYellow>
                </div>
              }
            </div>
            <div className="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2 space-y-4">
              <h6 className="mb-2 font-semibold leading-5">Follow our Dev</h6>
              <p className="text-sm text-gray-900">For 1000 $Alphas</p>
             
              {!followCharly ?
                <>{!loading || index !== 8 ?
                  <ButtonYellow
                    type="submit"
                    onclick={() => handleFollowCharly(8)}
                    className=" bg-red-400"
                    disabled={user?.followCharlyStatus}
            
                    buttontext='Follow '
                  >

                    Follow 


                  </ButtonYellow>
                  :
                  <>{index === 8 && <button disabled type="button" class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                    <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>
                    Loading...
                  </button>}</>}
                </> : <div class="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">

                  
                  </div>


                  <input type="text" id="text" onChange={handleTwitterUserName} value={twitterUserName || twitterDefaultUserName} class=" p-4 mb-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Twitter Username" required disabled={user?.followCharlyStatus} />
                  <ButtonYellow
                    type="submit"
                    onclick={handleFollowCharlyReward}
                    disabled={twitterDefaultUserName ? false : user?.followCharlyStatus || !twitterDefaultUserName || !twitterUserName}
                    buttontext=' Collect Reward'
                  >
                    Collect Reward &nbsp; <>{isLoading && index === 8 && <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>}</>
                  </ButtonYellow>
                </div>
              }
            </div>
            {/* <div className="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2 space-y-4">
              <h6 className="mb-2 font-semibold leading-5">Follow our Partner</h6>
              <p className="text-sm text-gray-900">For 1000 $Alphas</p>
             
              {!followDelta ?
                <>{!loading || index !== 10 ?
                  <ButtonYellow
                    type="submit"
                    onclick={() => handleFollowDelta(10)}
                    className=" bg-red-400"
                    disabled={user?.followDeltaStatus}
            
                    buttontext='Follow '
                  >

                    Follow 


                  </ButtonYellow>
                  :
                  <>{index === 10 && <button disabled type="button" class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                    <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>
                    Loading...
                  </button>}</>}
                </> : <div class="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">

                  
                  </div>


                  <input type="text" id="text" onChange={handleTwitterUserName} value={twitterUserName || twitterDefaultUserName} class=" p-4 mb-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Twitter Username" required disabled={user?.followDeltaStatus} />
                  <ButtonYellow
                    type="submit"
                    onclick={handleFollowDeltaReward}
                    disabled={twitterDefaultUserName ? false : user?.followDeltaStatus || !twitterDefaultUserName || !twitterUserName}
                    buttontext=' Collect Reward'
                  >
                    Collect Reward &nbsp; <>{isLoading && index === 10 && <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>}</>
                  </ButtonYellow>
                </div>
              }
            </div> */}

            <div className="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2 space-y-4">
              <h6 className="mb-2 font-semibold leading-5">Like & RT</h6>
              <p className="text-sm text-gray-900">For 2500 $Alphas</p>
             
              {!like ?
                <>{!loading || index !== 4 ?
                  <ButtonYellow
                    type="submit"
                    onclick={() => handleLike(4)}
                    className=" bg-red-400"
                    disabled={user?.likeStatus}
              
                    buttontext='Like'
                  >

                    Like


                  </ButtonYellow>
                  :
                  <>{index === 4 && <button disabled type="button" class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                    <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>
                    Loading...
                  </button>}</>}
                </> : <div class="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">


                  </div>


                  <input type="text" id="text" onChange={handleTwitterUserName} value={twitterUserName || twitterDefaultUserName} class=" p-4 mb-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Twitter Username" required disabled={user?.likeStatus} />
                  <ButtonYellow
                    type="submit"
                    onclick={handleLikeReward}
                    disabled={twitterDefaultUserName ? false : user?.likeStatus || !twitterDefaultUserName || !twitterUserName}
                    buttontext=' Collect Reward'
                  >
                    Collect Reward &nbsp; <>{isLoading && index === 4 && <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>}</>
                  </ButtonYellow>
                </div>
              }
            </div>
            {/* <div className="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2 space-y-4">
              <h6 className="mb-2 font-semibold leading-5">Like & RT</h6>
              <p className="text-sm text-gray-900">For 2500 $Alphas</p>
             
              {!likeAlpha ?
                <>{!loading || index !== 11 ?
                  <ButtonYellow
                    type="submit"
                    onclick={() => handleLikeAlpha(11)}
                    className=" bg-red-400"
                    disabled={user?.likeAlphaStatus}
              
                    buttontext='Like'
                  >

                    Like


                  </ButtonYellow>
                  :
                  <>{index === 11 && <button disabled type="button" class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                    <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>
                    Loading...
                  </button>}</>}
                </> : <div class="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">


                  </div>


                  <input type="text" id="text" onChange={handleTwitterUserName} value={twitterUserName || twitterDefaultUserName} class=" p-4 mb-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Twitter Username" required disabled={user?.likeAlphaStatus} />
                  <ButtonYellow
                    type="submit"
                    onclick={handleLikeAlphaReward}
                    disabled={twitterDefaultUserName ? false : user?.likeAlphaStatus || !twitterDefaultUserName || !twitterUserName}
                    buttontext=' Collect Reward'
                  >
                    Collect Reward &nbsp; <>{isLoading && index === 11 && <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>}</>
                  </ButtonYellow>
                </div>
              }
            </div> */}
            {/* <div className="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2 space-y-4">
              <h6 className="mb-2 font-semibold leading-5">Like & RT</h6>
              <p className="text-sm text-gray-900">For 2500 $Alphas</p>
             
              {!likeBeta ?
                <>{!loading || index !== 13 ?
                  <ButtonYellow
                    type="submit"
                    onclick={() => handleLikeBeta(13)}
                    className=" bg-red-400"
                    disabled={user?.likeBetaStatus}
              
                    buttontext='Like'
                  >

                    Like


                  </ButtonYellow>
                  :
                  <>{index === 13 && <button disabled type="button" class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                    <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>
                    Loading...
                  </button>}</>}
                </> : <div class="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">


                  </div>


                  <input type="text" id="text" onChange={handleTwitterUserName} value={twitterUserName || twitterDefaultUserName} class=" p-4 mb-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Twitter Username" required disabled={user?.likeBetaStatus} />
                  <ButtonYellow
                    type="submit"
                    onclick={handleLikeBetaReward}
                    disabled={twitterDefaultUserName ? false : user?.likeBetaStatus || !twitterDefaultUserName || !twitterUserName}
                    buttontext=' Collect Reward'
                  >
                    Collect Reward &nbsp; <>{isLoading && index === 13 && <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>}</>
                  </ButtonYellow>
                </div>
              }
            </div> */}
            <div className=" p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2 space-y-4">
              <h6 className="mb-2 font-semibold leading-5">Share this Tweet</h6>
              <p className="text-sm text-gray-900">For 2000 $Alphas</p>
              {!tweet ?
                <>{!loading || index !== 5 ?

                  <ButtonYellow
                    type="submit"
                    onclick={() => handleTweet(5)}
                    disabled={user?.tweetStatus}

                    buttontext='Tweet'

                  >
                    Tweet
                  </ButtonYellow>
                  :
                  <>{index === 5 &&
                    <button disabled type="button" class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                      <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                      </svg>
                      Loading...
                    </button>}</>}
                </>


                :

                <>


                  <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">


                    </div>
                    <input type="text" id="text" value={twitterTweet} onChange={handleTwitterTweet} class="mb-2 p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter Tweet Link" required disabled={user?.tweetStatus} />
                    <ButtonYellow
                      type="submit"
                      onclick={handleTweetReward}
                      disabled={user?.tweetStatus || !twitterTweet}
                      className='disabled:bg-slate-400'
                      buttontext='  Collect Reward'

                    >
                      Collect Reward &nbsp; <>{isLoading && index === 6 && <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                      </svg>}</>
                    </ButtonYellow>
                  </div>
                </>

              }
            </div>
            <div className=" p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2 space-y-4">
              <h6 className="mb-2 font-semibold leading-5">Join Our  Telegram</h6>
              <p className="text-sm text-gray-900">For 1000 $Alphas</p>

              {!tgAlpha ?
                <>{!loading || index !== 9 ?


                  <ButtonYellow
                    type="submit"
                    onclick={() => handleTgAlpha(9)}
                    disabled={user?.tgAlphaStatus}

                    buttontext='Telegram'

                  >
                    Telegram
                  </ButtonYellow>
                  :
                  <>{index === 9 &&
                    <button disabled type="button" class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                      <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                      </svg>
                      Loading...
                    </button>}</>}
                </>


                :

                <>


                  <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">

                    </div>
                    <input type="text" id="text" onChange={handleTgUserName} value={tgUserName || tgDefaultUserName} class="mb-2 p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Telegram Username" required disabled={user?.tgAlphaStatus} />
                    <ButtonYellow
                      type="submit"
                      onclick={handleTgAlphaReward}

                      disabled={tgDefaultUserName ? false : user?.tgAlphaStatus || !tgDefaultUserName || !tgUserName}
                      buttontext='Collect Reward'

                    >

                      Collect Reward &nbsp; <>{isLoading && index === 9 && <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                      </svg>}</>
                    </ButtonYellow>
                  </div>
                </>

              }
            </div>
            <div className=" p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2 space-y-4">
              <h6 className="mb-2 font-semibold leading-5">Join Our Partner's Telegram</h6>
              <p className="text-sm text-gray-900">For 1000 $Alphas</p>

              {!tg ?
                <>{!loading || index !== 6 ?


                  <ButtonYellow
                    type="submit"
                    onclick={() => handleTg(6)}
                    disabled={user?.tgStatus}

                    buttontext='Telegram'

                  >
                    Telegram
                  </ButtonYellow>
                  :
                  <>{index === 6 &&
                    <button disabled type="button" class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                      <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                      </svg>
                      Loading...
                    </button>}</>}
                </>


                :

                <>


                  <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">

                      {/* <div class='h-7'>
                        @

                      </div> */}
                    </div>
                    <input type="text" id="text" onChange={handleTgUserName} value={tgUserName || tgDefaultUserName} class="mb-2 p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Telegram Username" required disabled={user?.tgStatus} />
                    <ButtonYellow
                      type="submit"
                      onclick={handleTgReward}

                      disabled={tgDefaultUserName ? false : user?.tgStatus || !tgDefaultUserName || !tgUserName}
                      buttontext='Collect Reward'

                    >

                      Collect Reward &nbsp; <>{isLoading && index === 6 && <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                      </svg>}</>
                    </ButtonYellow>
                  </div>
                </>

              }
            </div>
          
            <div className=" p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2 space-y-4">
              <h6 className="mb-2 font-semibold leading-5">Join Our Partner's Telegram</h6>
              <p className="text-sm text-gray-900">For 1000 $Alphas</p>

              {!tgBeta ?
                <>{!loading || index !== 10 ?


                  <ButtonYellow
                    type="submit"
                    onclick={() => handleTgBeta(10)}
                    disabled={user?.tgBetaStatus}

                    buttontext='Telegram'

                  >
                    Telegram
                  </ButtonYellow>
                  :
                  <>{index === 10 &&
                    <button disabled type="button" class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                      <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                      </svg>
                      Loading...
                    </button>}</>}
                </>


                :

                <>


                  <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">

                    
                    </div>
                    <input type="text" id="text" onChange={handleTgUserName} value={tgUserName || tgDefaultUserName} class="mb-2 p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Telegram Username" required disabled={user?.tgBetaStatus} />
                    <ButtonYellow
                      type="submit"
                      onclick={handleTgBetaReward}

                      disabled={tgDefaultUserName ? false : user?.tgBetaStatus || !tgDefaultUserName || !tgUserName}
                      buttontext='Collect Reward'

                    >

                      Collect Reward &nbsp; <>{isLoading && index === 10 && <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                      </svg>}</>
                    </ButtonYellow>
                  </div>
                </>

              }
            </div>
            {/* <div className=" p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2 space-y-4">
              <h6 className="mb-2 font-semibold leading-5">Join Discord</h6>
              <p className="text-sm text-gray-900">For 125 $Alphas</p>

              {!discord ?
                <>{!loading || index !== 7 ?



                  <ButtonYellow
                    type="submit"
                    onclick={() => handleDiscord(7)}
                    disabled={user?.discordStatus}

                    buttontext='Discord'

                  >
                    Discord
                  </ButtonYellow>
                  :
                  <>{index === 7 &&
                    <button disabled type="button" class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                      <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                      </svg>
                      Loading...
                    </button>}</>}
                </>


                :

                <>


                  <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">


                    </div>
                    <input type="text" id="text" onChange={handleDiscordUserName} class="mb-2 p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Discord Username" required disabled={user?.discordStatus} />
                    <ButtonYellow
                      type="submit"
                      onclick={handleDiscordReward}
                      disabled={user?.discordStatus || !discordUserName}
                      buttontext='Collect Reward'

                    >

                      Collect Reward &nbsp; <>{isLoading && index === 7 && <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                      </svg>}</>
                    </ButtonYellow>
                  </div>
                </>

              }
            </div> */}

            <div className="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2 space-y-4">
              <h6 className="mb-2 font-semibold leading-5">
                Stay tuned for more task
              </h6>
            </div>
          </div>
        </div>
      </section>

      {/* Leaderboard */}
      <section>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold text-amber-400 sm:text-4xl md:mx-auto">
              Leaderboard
            </h2>
            <p className="text-base text-white md:text-lg">View your ranking</p>
          </div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left rtl:text-right text-white dark:text-white">
              <thead class="text-xs text-neutral-900 uppercase bg-yellow dark:bg-amber-400 ">
                <tr>
                  <th scope="col" class="p-4">
                    <div class="flex items-center">
                      <label for="checkbox-all-search" class="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Wallet
                  </th>
                  <th scope="col" class="px-6 py-3">
                    $Alphas
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.slice(0, 100).map((usermap, index) => (


                  <tr class={` border-b dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600 ${index + 1 === myRank ? 'hidden' : ""}`}>
                    <td class="w-1 p-4">
                      <div class="flex items-center">
                        <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                      </div>
                    </td>

                    <th scope="row" class="px-6 py-4 font-medium text-white whitespace-nowrap dark:text-white">
                      <span className="text-yellow">{index + 1}.</span>
                      &nbsp;
                      &nbsp;
                      &nbsp;
                      <>




                        {usermap?.walletAddress?.slice(0, 6) + "..." + usermap?.walletAddress?.slice(usermap?.walletAddress?.length - 4, usermap?.walletAddress?.length)}



                      </>
                    </th>

                    <td class="px-6 text-white py-4">
                      {usermap?.totalPoint}
                    </td>

                  </tr>

                )


                )}
             
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Airdrop;
